import React, {useState} from "react";
import HelpCenterLinks from "./helpCenterLinks"
import btLogo from "../../images/logos/logo-black.svg"
import btWebLogo from "../../images/logos/bittorrent-web-icon.svg"
import utLogo from "../../images/logos/utorrent-black.svg"
import utWebLogo from "../../images/logos/utorrent-web-icon.svg"
import KeyIcon from "../../images/icons/key.svg"
import UserIcon from "../../images/icons/user-icon.svg"
import {Link, navigate} from "gatsby";
import Axios from "axios";
import {Spinner, UncontrolledTooltip} from "reactstrap";
import useTranslations from "../../utils/useTranslations";
import fileDownload from "js-file-download";
import { Base64 } from 'js-base64';

export default (props) => {
    const t = useTranslations(props.text)
    //Needs all products
    // const products = {
    //
    //     classic: {
    //         brand: "BitTorrent",
    //         name: "Classic",
    //         logo: BtLogo,
    //     }
    //
    // }

    const userInfo = JSON.parse(sessionStorage.getItem(HelpCenterLinks.USER_INFO));
    const [resendState, updateResendState] = useState({index: -1, state: 0});


    function resendKey(product, index) {
        updateResendState({
            index: index,
            state: 1
        })

        let body = {
            purchaseId: product.purchaseId,
            email: userInfo.email,
        }

        Axios.post("https://5tfj2zmxq2.execute-api.us-east-1.amazonaws.com/bt-help-center/email", body)
            .then((res) => {
                //handle complete
                updateResendState({
                    index: index,
                    state: 2
                })
            })
            .catch((err) => {
                //handle err
            })
    }

    function downloadKey(product, index) {
        updateResendState({
            index: index,
            state: 1
        });
        
        let isWeb = product.orderItemName.toLowerCase().includes("web");
        const filename = isWeb ? 'license.btwkey' : 'license.btkey';
        if (product.licenseKeys.length) {
            fileDownload(Base64.toUint8Array(product.licenseKeys[0]), filename);
            updateResendState({
                index: index,
                state: 2
            });
        } else {
            console.error('No license attached to the product')
        }
    }

    function renewLicense(product, index){

    }

    function getLogo(productName){
        let isBitTorrent = !productName.includes("µTorrent");
        let isWeb = productName.includes("Web")

        if(isBitTorrent){
            return isWeb ? btWebLogo : btLogo;
        }else{
            return isWeb ? utWebLogo : utLogo;
        }
    }

    function changeUser() {
        if (props.changeUser !== undefined) {
            props.changeUser();
        }
    }

    function getExpireDate(date) {
        return new Date(date).toLocaleDateString();
    }
    
    function getExpireDatePPG(product) {
        console.log(product);
        let dateExpires = Date.now();
        if (!product.subscriptionDetails) {
            let created = new Date(product.dateCreated);
            console.log(created)
            dateExpires = created.setFullYear(created.getFullYear() + 1);
        } else {
            dateExpires = new Date(product.subscriptionDetails.nextPayment);
        }
        console.log(dateExpires)
        return dateExpires.toLocaleDateString();
    }

    function canRenewLicense(product) {
        return false;
    }

    return <>
            <div className="help-center-premium-dashboard">
                <div className="premium-dashboard-container">

                    <div className="header-card">
                        <div className={"welcome-text"}>{t("premiumWelcome")}</div>
                        <div className="user-email">{userInfo.email}</div>


                        <div className="assistance-card">
                            <div className="assistance-title"><b>{t("premiumAssistance")}</b></div>
                            <Link className="submit-link no-decoration" to={HelpCenterLinks.submitTicket+`?email=${userInfo.email}`}><div
                                className="submit-ticket">{t("premiumSubmitTicket")}</div></Link>
                        </div>
                        <div className="product-options">
                            <div id="billingInfo" className="billing-info">{t("premiumBillingInfo")}</div>
                                <UncontrolledTooltip placement="top" target={"billingInfo"}
                                                     className={"billing-tooltip"}>
                                  {t("premiumBillingInfoDescription")}
                                </UncontrolledTooltip>
                            <div onClick={changeUser} className="change-user">
                                <img src={UserIcon} className="change-user-icon" />
                                {t("premiumChangeUser")}
                            </div>
                        </div>
                    </div>

                    <div className="product-card">
                        <div>
                            <div className="product-header text-center">{t("premiumProducts")}</div>

                            <div className="user-products">

                            {userInfo.orders.map((order, oIndex) => {
                                return order.orderItems.map((product, pIndex) => 
                                    <div className="product" key={product.orderItemName + oIndex + pIndex}>
                                    <div className="product-brand">
                                        <div>
                                            <img className="product-logo" src={getLogo(product.orderItemName)} />
                                        </div>
                                        <div className="product-name">
                                            {product.orderItemName}
                                        </div>
                                    </div>

                                    <div className="product-details">
                                        <div className={"button-holder"}>
                                            {
                                                product.licenseKeys.length > 0 && <>
                                                        <div className="resend-key" onClick={() => {downloadKey(product, oIndex+pIndex)}}>
                                                            {
                                                                resendState.index === oIndex+pIndex && <>
                                                                    {resendState.state === 1 && <Spinner className={"spinner"} size="sm" />}
                                                                    {resendState.state === 2 && <span>{t("premiumDownloaded")}</span>}
                                                                </>
                                                            }
                                                            {
                                                                resendState.index !== oIndex+pIndex && <> {t("premiumDownloadKey")}
                                                                <img src={KeyIcon} className="key-icon" />
                                                                </>
                                                            }
                                                        </div>
                                                    </>

                                            }
                                            {
                                                canRenewLicense(product) && <>
                                                        <div className="renew-license" onClick={() => {renewLicense(product, oIndex+pIndex)}}>
                                                            <span>Renew License</span>
                                                        </div>
                                                    </>
                                            }
                                            

                                        </div>
                                    </div>

                                    <div className="expire">
                                            <b>{t("premiumExpires")}</b><span> {getExpireDatePPG(product)}</span>
                                    </div>

                                </div>
                                );
                            })}
                             
                                
                            {userInfo.purchases.map((product, index) =>
                                <div className="product" key={product.name + index}>
                                    <div className="product-brand">
                                        <div>
                                            <img className="product-logo" src={getLogo(product.name)} />
                                        </div>
                                        <div className="product-name">
                                            {product.name}
                                        </div>
                                    </div>

                                    <div className="product-details">
                                        <div className={"button-holder"}>
                                             <div className="resend-key" onClick={() => {
                                                 resendKey(product, index)
                                             }}>

                                            {resendState.index === index && <>
                                                {resendState.state === 1 && <Spinner className={"spinner"} size="sm" />}
                                                {resendState.state === 2 && <span>{t("premiumSent")}</span>}

                                            </>}
                                                 {resendState.index !== index && <>
                                                {t("premiumResendKey")}
                                            <img src={KeyIcon} className="key-icon" />
                                            </>}
                                        </div>

                                        <div className="renew-license" onClick={() => {
                                            renewLicense(product, index)
                                        }}>
                                            <span>Renew License</span>
                                        </div>

                                        </div>
                                    </div>

                                    <div className="expire">
                                            <b>{t("premiumExpires")}</b><span> {getExpireDate(product.nextBillingDate)}</span>
                                    </div>

                                </div>
                                )}
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
}