import React, {useState} from "react"
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Label
} from "reactstrap";
import PageRightIcon from "../../images/icons/pageRightIcon"
import PageLeftIcon from "../../images/icons/pageLeftIcon"
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterBlog from "../../components/help-center/helpCenterBlog";
import BittorrentLogo from "../../images/logos/logo-black.svg"
import SpeedLogo from "../../images/logos/speed-icon-black.svg"
import UtorrentLogo from "../../images/logos/utorrent-black.svg"
import arrowWhite from "../../images/icons/arrow-white.svg";
import arrowGrey from "../../images/icons/arrow-grey.svg";
import DropDownArrow from "../../images/icons/drop-down-white.svg";
import HelpCenterLinks from "../../components/help-center/helpCenterLinks";
import HelpCenterPremiumDashboard from "../../components/help-center/helpCenterPremiumDashboard";
import HelpCenterUserLogin from "../../components/help-center/helpCenterUserLogin";
import {Link, navigate} from "gatsby";
import HelpCenterRating from "../../components/help-center/helpCenterRating";
import useTranslations from "../../utils/useTranslations";


export default ({location, pageContext: {popularTopics, latestNews,text, locale}}) => {
    const t = useTranslations(text)



    // let locale = "en";
    const loginProp = new URLSearchParams(location.search).get('showLogin');
    let userInfo = null;
    if (typeof window !== 'undefined') {
        userInfo = sessionStorage.getItem(HelpCenterLinks.USER_INFO);
    }

    const [localUserInfo, updateLocalUserInfo] = useState(userInfo === null ? "" : userInfo);
    const [currentIndex, updateCurrentIndex] = useState(0);
    const [dropDownOpenIndex, setDropdownOpen] = useState(-1);
    const [showLogin, updateShowLogin] = useState(loginProp === null ? false : loginProp);


    const btLinks = [
        {brand: "BitTorrent", name: "Classic",   link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BT_CLASSIC)},
        {brand: "BitTorrent", name: "Web",       link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BT_WEB)},
        {brand: "BitTorrent", name: "Android",   link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BT_ANDROID) },
        // {brand: "BitTorrent", name: "Speed",     link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BT_SPEED)},
        // {brand: "BitTorrent", name: "BTT Token", link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BTT_TOKEN)},
        // {brand: "BitTorrent", name: "Play",      link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.BT_PLAY)},
    ]


    const utLinks = [
        {brand: "µTorrent", name: "Classic",    link: HelpCenterLinks.solutionLink(locale, HelpCenterLinks.UT_CLASSIC)},
        {brand: "µTorrent", name: "Web",        link: HelpCenterLinks.solutionLink(locale, HelpCenterLinks.UT_WEB)},
        {brand: "µTorrent", name: "Android",    link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.UT_ANDROID)},
        {brand: "µTorrent", name: "Lite Beta",      link: HelpCenterLinks.solutionLink(locale,HelpCenterLinks.UT_LIGHT)},

    ]


    function newsIndexUpdate(isForward) {
        if (isForward) {
            if (currentIndex < latestNews.length - 1) {
                updateCurrentIndex(currentIndex + 1)
            }
        } else {
            if (currentIndex > 0) {
                updateCurrentIndex(currentIndex - 1)
            }
        }
    }

    function updateOpenDropDown(index) {
        if (dropDownOpenIndex === index) {
            setDropdownOpen(-1);
        } else {
            setDropdownOpen(index);
        }
    }

    function closeLogin(){
        updateShowLogin(false)
    }

    function showPremium(newUserInfo){
        if (typeof window !== 'undefined') {
            sessionStorage.setItem(HelpCenterLinks.USER_INFO, JSON.stringify(newUserInfo));
        }
        updateLocalUserInfo(newUserInfo);
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
    }
    function changeUser(){
        if (typeof window !== 'undefined') {
            sessionStorage.removeItem(HelpCenterLinks.USER_INFO)
        }
        updateLocalUserInfo(null)
        updateShowLogin(true)
    }

    function isUserLoggedIn(){
        return localUserInfo !== null  && localUserInfo !== "";
    }


    return (
        <>
            <div className="help-center-home-page">

                {showLogin && <HelpCenterUserLogin closeLogin={closeLogin} onSuccess={showPremium} text={text}/>}

                <HelpCenterSearch text={text} />

                <div className="body">

                {isUserLoggedIn() && <HelpCenterPremiumDashboard   userInfo={localUserInfo} changeUser={changeUser} text={text}/>}

                <h2 className="header-text">{t("homeHeader")}</h2>
                <div className="app-containers">

                    <div className="app-selector-container">
                        <div>
                            <img src={BittorrentLogo} className="app-logo"  alt={""}/>
                            <Label className="app-selector-title font-weight-bold" for="bitTorrentSelector">BitTorrent</Label>
                        </div>
                       <Dropdown isOpen={dropDownOpenIndex === 0} toggle={() => (updateOpenDropDown(0))} >
                            <DropdownToggle className="dropdown-button">{t("productSelection")}
                                <span className={"dropdown-icon"}>
                                    <img src={DropDownArrow}  className={dropDownOpenIndex === 0 ? "flip-icon":""} alt={"drop down"}/>
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu" flip={false}>
                                {btLinks.map(product =>
                                    <Link className={"dropdown-item"} to={product.link}  key={product.brand + product.name}>
                                        <DropdownItem className={"dropdown-text"}>
                                            <span className={"font-weight-bold brand-font"}>{product.brand}</span> {product.name}
                                        </DropdownItem>
                                    </Link>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="app-selector-container">
                        <div>
                            <img src={UtorrentLogo} className="app-logo"  alt={""}/>
                            <label className="app-selector-title font-weight-bold">µTorrent</label>
                        </div>
                        <Dropdown isOpen={dropDownOpenIndex === 1} toggle={() => (updateOpenDropDown(1))}>
                            <DropdownToggle className="dropdown-button">{t("productSelection")}
                                <span className={"dropdown-icon"}>
                                    <img src={DropDownArrow}  className={dropDownOpenIndex === 1 ? "flip-icon":""}  alt={"drop down"}/>
                                </span>
                            </DropdownToggle>

                                <DropdownMenu className="dropdown-menu" flip={false}>
                                {utLinks.map(product =>
                                    <Link className={"dropdown-item"} to={product.link} key={product.brand + product.name}>
                                        <DropdownItem className={"dropdown-text"}>
                                            <span className={"font-weight-bold  brand-font"}>{product.brand}</span> {product.name}
                                        </DropdownItem>
                                    </Link>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="app-selector-container">
                        <div>
                            <img src={SpeedLogo} className="speed-logo"  alt={""}/>
                            <label className="app-selector-title"><span className={"font-weight-bold"}>BitTorrent</span> Speed</label>
                        </div>
                        <Link className="no-decoration"
                           to={HelpCenterLinks.solutionLink(locale, HelpCenterLinks.BT_SPEED)}><div
                            className="dropdown-button">{t("learnMore")}</div></Link>
                    </div>

                </div>

                { !isUserLoggedIn() && <div className="premium-support-container">
                        <div>{t("paidMemberHome")}</div>
                        <div className="help-center-button button" onClick={()=>updateShowLogin(true)}>{t("premiumButtonHome")} <img src={arrowWhite}
                                                                                    alt="premium support" /></div>
                </div>}


                <h1 className="text-center popular-topics-header">{t("popularTopics")}</h1>
                <div className="popular-topics">
                    {popularTopics.map((topic) =>

                        <Link className={"topic-card"} to={topic.link} key={topic.title}>
                                <div className="topic-title">{topic.title}</div>
                                <div className="topic-description">{t("learnMore")} <img src={arrowGrey}
                                                                                   alt="learn more" /></div>
                            </Link>
                    )}
                </div>



                <div className="divider" />

                <div className={"latest-news"}>
                    <div className="news-card" onClick={()=>{navigate(HelpCenterLinks.solutionLink(locale, HelpCenterLinks.ANNOUNCEMENTS))}}>
                        <h2>{t("announcements")}</h2>
                        <div className={"read-more"}>{t("seeAll")} <img src={arrowGrey} alt="learn more" /></div>
                    </div>

                    {latestNews[currentIndex] && <div className="news-card" onClick={()=>{navigate(latestNews[currentIndex].link)}}>
                        <div className="news-title">{latestNews[currentIndex].title}</div>
                        <div className="read-more">{t("readMore")} <img src={arrowGrey} alt="learn more" /></div>
                    </div>}


                    {currentIndex + 1 < latestNews.length && latestNews[currentIndex + 1] &&
                        <div className="news-card" onClick={()=>{navigate(latestNews[currentIndex + 1].link)}}>
                            <div className="news-title">{latestNews[currentIndex + 1].title}</div>
                            <div className="read-more">{t("readMore")} <img src={arrowGrey} alt="learn more" /></div>
                        </div>}



                </div>
                <div className={"float-right"}>

                    <span className={(currentIndex > 0 ? "pointer" : "") + " p-2"} onClick={() => {
                        newsIndexUpdate(false)
                    }}>
                        <PageLeftIcon extraClass={currentIndex > 0 ? "" : "grey"} />

                    </span>

                    <span className={(currentIndex < latestNews.length - 1 ? "pointer" : "") + " ml-5 p-2"}
                          onClick={() => {
                              newsIndexUpdate(true)
                          }}>
                        <PageRightIcon extraClass={currentIndex < latestNews.length - 1 ? "" : "grey"} />
                    </span>


                </div>

                <div className="blog-holder ml-auto mr-auto">
                    <HelpCenterBlog text={text} />
                    <HelpCenterRating text={text} />
                </div>
                </div>

            </div>

    </>
    )
}